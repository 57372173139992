<template>
  <!-- navigation:default START -->
  <div class="main-navigation">

    <!-- code START -->
    <div
      :class="[
        'code',
        { 'active' : eanActive }
      ]"
    >
      <img
        v-if="$route.name === 'cart'"
        v-on:click="toggleEan"
        height="24"
        src="@/assets/images/temp/icon-ean@3x.png"
      />
    </div>
    <!-- code END -->

    <!-- logo START -->
    <div class="logo">
      <router-link :to="'/home'">
        <img height="24" src="@/assets/images/logo-syreta-full.png" />
      </router-link>
    </div>
    <!-- logo END -->

    <!-- cart:icon START -->
    <div class="cart-icon">
      <div
        class="cart-icon-inner"
        v-if="$store.state.locationID !== null && activateNav"
      >
        <router-link :to="'/cart'">
          <span
            class="cart-count"
            v-if="cartCount"
            v-html="cartCount"
          ></span>
        </router-link>
      </div>
    </div>
    <!-- cart:icon END -->
  </div>
  <!-- navigation:default END -->
</template>

<script>
export default {
  name: 'default-navigation',
  computed: {
    storeCart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return null;
    },

    cartCount() {
      if (this.storeCart) {
        return this.storeCart.length;
      }
      return 0;
    },

    eanActive: {
      get() {
        return this.$store.getters.eanActive;
      },
      set(value) {
        return this.$store.commit('SET_EAN', value);
      },
    },

    activateNav() {
      return !!(sessionStorage.getItem('activateNav') && Boolean(sessionStorage.getItem('activateNav')) === true);
    },
  },
  methods: {
    toggleEan() {
      this.eanActive = !this.eanActive;
      this.$store.commit('SET_EAN', this.eanActive);
    },
  },
};
</script>
